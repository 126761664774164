<template>
  <div>
    <div class="searchDiv">
      <search-bar v-model:value="formState.name" :on-search="getList" />
    </div>
    <div v-show="!pageData.firstLoading">
      <div class="formDiv">
        <a-form layout="inline">
          <a-row style="width: 100%" :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="歌曲识别码">
                <a-input v-model:value="formState.song_code" @keydown.enter="getList" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="歌曲简拼">
                <a-input v-model:value="formState.py" />
              </a-form-item>
            </a-col>
            <template v-if="pageData.advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="状态">
                  <a-select
                    v-model:value="formState.status"
                    :options="formOpts.status"
                    @search="handleTagSearch"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="标签">
                  <a-select
                    v-model:value="formState.tag"
                    mode="multiple"
                    placeholder="可选择标签"
                    style="width: 100%"
                    :options="formOpts.tags"
                    @search="handleTagSearch"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="所属教材章节">
                  <a href="#" @click="() => (pageData.modalVisible = true)">{{
                      formOpts.bookOpts.showName
                    }}</a>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!pageData.advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="
                  (pageData.advanced && { float: 'right', overflow: 'hidden' }) ||
                  {}
                "
              >
                <a-button type="primary" @click="getList">查询</a-button>
                <a-button style="margin-left: 8px" @click="handleReset"
                >重置</a-button
                >
                <a
                  @click="
                    () => {
                      pageData.advanced = !pageData.advanced;
                    }
                  "
                  style="margin-left: 8px"
                >
                  {{ pageData.advanced ? "收起" : "展开" }}
                  <up-outlined v-show="pageData.advanced" />
                  <down-outlined v-show="!pageData.advanced" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="tableDiv">
        <div class="tableOperator">
          <a-button
            type="primary"
            @click="handleCreate"
            v-permission="PermissionMap.ENJOY_SONGS_EDIT"
          >
            新建
          </a-button>
          <a-button
            v-permission="PermissionMap.ENJOY_SONGS_EDIT"
            @click="router.push('/resource/enjoy-songs/import')"
          >
            批量导入
          </a-button>
          <a-button
            v-permission="PermissionMap.ENJOY_SONGS_EDIT"
            @click="handlePublishTest"
          >
            批量设置为测试版
          </a-button>
          <a-button
            v-permission="PermissionMap.ENJOY_SONGS_PUBLISH"
            @click="handlePublish"
          >
            批量设置为正式版
          </a-button>
        </div>
        <table-select-info
          v-if="hasSelected"
          :total="tableState.selectedRowKeys.length || 0"
          @clearSelected="tableState.selectedRowKeys = []"
        />
        <a-table
          :row-selection="{
            selectedRowKeys: tableState.selectedRowKeys,
            onChange: onSelectChange
          }"
          :dataSource="tableState.data"
          :columns="tableState.columns"
          rowKey="id"
          :pagination="tableState.pagination"
          :loading="tableState.loading"
          @change="handleTableChange"
          :scroll="{ x: 1500 }"
        >
          <template #action="{ record }">
            <router-link
              :to="`/resource/enjoy-songs/${record.id}/detail/1`"
            >
              详情
            </router-link>
            <a-divider
              type="vertical"
            />
            <router-link
              v-permission="PermissionMap.ENJOY_SONGS_EDIT"
              :to="`/resource/enjoy-songs/${record.id}/edit/${pageData.type}`"
            >
              编辑
            </router-link>
            <a-divider
              v-permission="PermissionMap.ENJOY_SONGS_EDIT"
              type="vertical"
            />
            <router-link
              v-permission="PermissionMap.ENJOY_SONGS_VERSION"
              :to="`/resource/enjoy-songs/${record.id}/version`"
            >
              版本管理
            </router-link>
            <a-divider
              v-permission="PermissionMap.ENJOY_SONGS_VERSION"
              type="vertical"
            />
            <a
              v-permission="PermissionMap.ENJOY_SONGS_COPY"
              href="#"
              @click="handleCopy(record)"
            >
              复制到我的歌曲
            </a>
            <a-divider
              v-permission="PermissionMap.ENJOY_SONGS_COPY"
              type="vertical"
            />
            <a-popconfirm
              title="确定删除这首歌曲吗?"
              ok-text="删除"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a href="#" v-permission="PermissionMap.ENJOY_SONGS_DELETE">删除</a>
            </a-popconfirm>
            <a-divider
              type="vertical"
            />
            <a-dropdown>
              <a @click.prevent v-permission="PermissionMap.ENJOY_SONGS_PUBLISH">
                状态设置
                <DownOutlined />
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item v-for="(v, k) in statusMap" :key="k" @click="handleSetStatus(record.id, k)">{{v.name}}</a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </template>
          <template #tags="{ text }">
            <span v-if="!text.length">--</span>
            <span v-else>
              <a-tooltip
                :title="text.map((tItem) => tItem.tag).toString()"
                placement="topLeft"
              >
                <span :key="item.tag" v-for="item in text">
                  <a-tag class="tag_item_label">{{ item.tag }}</a-tag>
                </span>
              </a-tooltip>
            </span>
          </template>
          <template #status="{ text }">
            <span>{{ statusMap[text].name }}</span>
          </template>
        </a-table>
      </div>
    </div>
    <a-skeleton v-if="pageData.firstLoading" />
    <select-book-modal
      ref="modalRef"
      :type="pageData.type"
      v-model:visible="pageData.modalVisible"
      @ok="handleSelectBookSection"
    />
  </div>

</template>

<script>
import SearchBar from '@/components/common/SearchBar'
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
import { computed, onBeforeMount, reactive, ref, onActivated } from 'vue'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import TableSelectInfo from '@/components/common/TableSelectInfo'

import { getTags } from '@/services/common'
import {
  getOfficialEnjoySongs,
  deleteOfficialEnjoySong,
  copyEnjoySong,
  batchSetEnjoySongTest,
  batchSetEnjoySongProduction,
  setEnjoySongStatus
} from '@/services/songs'
import { debounce } from '@/utils/common'
import { statusMap } from '@/utils/dataMap'
import { PermissionMap } from '@/utils/auth'
import SelectBookModal from '@/views/songs/components/SelectBookModal'

export default {
  name: 'List',
  components: {
    TableSelectInfo,
    SearchBar,
    DownOutlined,
    UpOutlined,
    SelectBookModal
  },
  setup () {
    const modalRef = ref()
    const router = useRouter()
    const pageData = reactive({
      type: 1,
      firstLoading: true,
      advanced: false,
      tagParams: {
        pageSize: 50,
        page: 1,
        keyword: ''
      },
      modalVisible: false
    })

    const formState = reactive({
      name: '',
      song_type: -1,
      book_section_id: [],
      song_code: '',
      py: '',
      status: -1,
      tag: []
    })
    const formOpts = reactive({
      status: [{ label: '全部', value: -1 }],
      bookOptions: [],
      bookOpts: {
        showName: '教材名称-章节目录'
      },
      defaultBookOpts: {
        showName: '教材名称-章节目录'
      }
    })

    const tableState = reactive({
      selectedRowKeys: [],
      data: [],
      columns: [],
      loading: true,
      pagination: {
        showSizeChanger: true,
        current: 1,
        total: 1,
        pageSize: 10,
        showTotal: total => `共${total}条`,
        showQuickJumper: true
      }
    })

    const hasSelected = computed(() => tableState.selectedRowKeys.length > 0)

    const onSelectChange = selectedRowKeys => {
      tableState.selectedRowKeys = selectedRowKeys
    }

    tableState.columns = [
      {
        title: '歌曲名称',
        dataIndex: 'name',
        ellipsis: true,
        width: 200
      },
      {
        title: '歌曲识别码',
        dataIndex: 'song_code',
        width: 140
      },
      {
        title: '教材',
        dataIndex: 'book.name',
        width: 140,
        ellipsis: true
      },
      {
        title: '教材出版社',
        dataIndex: 'book.press_version.name',
        ellipsis: true,
        width: 140
      },
      {
        title: '章节',
        dataIndex: 'section_breadcrumb',
        width: 180,
        ellipsis: true
      },
      {
        title: '歌曲简拼',
        dataIndex: 'py',
        width: 100,
        ellipsis: true
      },
      {
        title: '标签',
        dataIndex: 'tags',
        width: 200,
        ellipsis: true,
        slots: { customRender: 'tags' }
      },
      {
        title: '状态',
        dataIndex: 'status',
        width: 120,
        ellipsis: true,
        slots: { customRender: 'status' }
      },
      {
        title: '创建时间',
        dataIndex: 'created_at',
        width: 180,
        ellipsis: true
      },
      {
        title: '修改时间',
        dataIndex: 'updated_at',
        width: 180,
        ellipsis: true
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 380,
        slots: { customRender: 'action' }
      }
    ]

    const getList = debounce(async (params) => {
      tableState.loading = true
      params = { ...queryParams.value, page: 1, ...params }
      const { items, pagination } = await getOfficialEnjoySongs(params)
      tableState.data = items
      tableState.loading = false
      tableState.pagination = { ...tableState.pagination, ...pagination }
    })

    const handleTableChange = (pag) => {
      tableState.pagination = pag
      getList({ page: tableState.pagination.current })
    }

    const queryParams = computed(() => {
      const params = { ...formState }
      if (params.song_type === -1) {
        delete params.song_type
      }
      if (params.song_code === '') {
        delete params.song_code
      }
      if (params.book_section_id.length === 0) {
        delete params.book_section_id
      } else if (params.book_section_id.length === 1) {
        params.book_id = params.book_section_id[0]
        delete params.book_section_id
      } else {
        params.book_id = params.book_section_id[0]
        params.book_section_id = params.book_section_id[params.book_section_id.length - 1]
      }
      for (const key in params) {
        if (params[key] === '' || (key === 'status' && params[key] === -1)) {
          delete params[key]
        }
      }
      params.per_page = tableState.pagination.pageSize
      params.page = tableState.pagination.current
      return params
    })

    const getTagsOptions = debounce(async () => {
      const params = {
        per_page: pageData.tagParams.pageSize,
        current: pageData.tagParams.page,
        keyword: pageData.tagParams.keyword
      }
      formOpts.tags = (await getTags(params)).items.map(i => {
        i.label = i.tag
        i.value = i.tag
        return i
      })
    })

    const handleDelete = async (record) => {
      await deleteOfficialEnjoySong(record.id)
      message.success('删除成功')
      getList({ page: tableState.pagination.current })
    }

    const handleCreate = async () => {
      router.push(`/resource/enjoy-songs/create/${pageData.type}`)
    }

    const handleReset = () => {
      modalRef.value.handleReset()
      formState.status = -1
      formState.name = ''
      formState.song_type = -1
      formState.book_section_id = []
      formState.song_code = ''
      formState.py = ''
      formState.tag = []
      formOpts.bookOpts.showName = formOpts.defaultBookOpts.showName
      tableState.pagination.current = 1
      getTagsOptions()
      getList()
    }

    const handleSelectBookSection = (value) => {
      pageData.modalVisible = false
      if (value.values.length > 0) {
        formOpts.bookOpts.showName = value.labels.join('-')
        formState.book_section_id = value.values
      } else {
        formOpts.bookOpts.showName = formOpts.defaultBookOpts.showName
      }
      getList()
    }

    const handleTagSearch = v => {
      pageData.tagParams.page = 1
      pageData.tagParams.keyword = v
      getTagsOptions()
    }

    const handleTypeChange = () => {
      getList()
      getTagsOptions()
    }

    const handleCopy = async (record) => {
      const key = 'copy'
      try {
        await copyEnjoySong(record.id)
        message.loading({
          key,
          content: '正在处理'
        })
        message.success({
          key,
          content: '复制成功'
        })
      } catch (e) {
        message.error({
          key,
          content: '操作失败'
        })
      }
    }

    const handlePublish = async () => {
      if (!tableState.selectedRowKeys.length) {
        message.warning('请选择歌曲')
        return
      }
      await batchSetEnjoySongProduction({ enjoy_song_ids: tableState.selectedRowKeys })
      message.success('操作成功')
      tableState.selectedRowKeys = []
      getList()
    }

    const handlePublishTest = async () => {
      if (!tableState.selectedRowKeys.length) {
        message.warning('请选择歌曲')
        return
      }
      await batchSetEnjoySongTest({ enjoy_song_ids: tableState.selectedRowKeys })
      message.success('操作成功')
      tableState.selectedRowKeys = []
      getList()
    }

    const handleSetStatus = async (id, status) => {
      await setEnjoySongStatus(id, { status })
      message.success('设置成功')
      getList({ page: tableState.pagination.current })
    }

    onBeforeMount(async () => {
      for (const k in statusMap) {
        formOpts.status.push({ value: k, label: statusMap[k].name })
      }
      await Promise.all([
        getList(),
        getTagsOptions()
      ])
      pageData.firstLoading = false
    })

    onActivated(() => {
      getList({ page: tableState.pagination.current })
    })

    return {
      statusMap,
      modalRef,
      pageData,
      formState,
      formOpts,
      tableState,
      getList,
      PermissionMap,
      hasSelected,
      router,
      handleTableChange,
      handleDelete,
      handleReset,
      handleCreate,
      handleTagSearch,
      handleSelectBookSection,
      handleTypeChange,
      handleCopy,
      onSelectChange,
      handlePublish,
      handlePublishTest,
      handleSetStatus
    }
  }
}
</script>

<style lang="less" scoped>
.searchDiv {
  width: 100%;
  padding-top: 26px;
  padding-bottom: 22px;
  background-color: #ffffff;
}
.formDiv {
  background-color: white;
  margin: 30px 30px 0 30px;
  padding: 20px 38px;
}
.tableDiv {
  background-color: white;
  margin: 30px 30px 0 30px;
  padding: 20px 38px;
  .iconExtra {
    display: inline-block;
    font-size: 24px;
    margin: 2px 6px 0 0;
  }
  .cardTableDiv {
    padding-left: 20px;
  }
  .pagination {
    margin-top: 20px;
    text-align: right;
  }
}
.ant-col {
  margin-top: 10px;
}
.tableOperator {
  text-align: right;
  margin-bottom: 12px;
  button{
    margin: 0 10px;
  }
}

.tag_item_label {
  max-width: 100px;
  overflow: hidden; //超出一行文字自动隐藏
  text-overflow: ellipsis; //文字隐藏后添加省略号
  white-space: nowrap; //强制不换行
  cursor: default;
}
</style>
